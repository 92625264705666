import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import styled from "styled-components";
import { MDXProvider } from "@mdx-js/react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/SEO";
import Container from "../components/Container";
import format from "date-fns/format";

const SinglePostPageStyles = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.3em;
    padding: 30px 0;
    text-shadow: none;
    text-align: center;
    margin: 0;

    @media (min-width: 440px) {
      font-size: 1.5em;
    }

    @media (min-width: 920px) {
      font-size: 3em;
    }
  }

  h2 {
    text-align: center;
  }

  small {
    align-self: flex-end;
    margin-top: 15px;
  }

  .gatsby-resp-image-wrapper {
    max-width: 50% !important;
  }

  .text {
    padding: 150px 0;
  }
`;

const components = {
  StaticImage,
};

const SinglePostPage = ({ data: { mdx: post }, location }) => (
  <MDXProvider components={components}>
    <Container>
      <SinglePostPageStyles>
        <SEO
          title={post.frontmatter.title}
          location={location}
          description={post.frontmatter.description}
        />
        <h1>{post.frontmatter.title}</h1>
        <MDXRenderer>{post.body}</MDXRenderer>
      </SinglePostPageStyles>
    </Container>
  </MDXProvider>
);

SinglePostPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.any,
};

export default SinglePostPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      id
      excerpt
      fileAbsolutePath
      frontmatter {
        title
        slug
        date
        description
        category
        embeddedImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
      body
    }
  }
`;
